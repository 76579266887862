var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"movements-table","headers":_vm.movementsHeaders,"items":_vm.movements,"footer-props":_vm.movementsTableFooterProps,"items-per-page":_vm.movementsPerPage,"search":_vm.search,"loading":_vm.loading,"calculate-widths":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"movement-code",staticStyle:{"padding":"0 5px"}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.code)+" ")])],1),_c('td',{staticClass:"movement-category",staticStyle:{"padding":"0 5px"}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.category)+" ")])],1),_c('td',{staticClass:"movement-model",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.model))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.manufacturer))])])],1),_c('td',{staticClass:"movement-serial-number",staticStyle:{"padding":"0 5px"}},[_vm._v(" "+_vm._s(item.serial_number ? item.serial_number : '-')+" ")]),_c('td',{staticClass:"movement-user",staticStyle:{"padding":"0 5px"}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user_name)+" ")])],1),_c('td',{staticClass:"movement-location",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({ color: item.locationColor })},on),[_vm._v(_vm._s(item.location_simple))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.location))])])],1),_c('td',{staticClass:"movement-condition",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({ color: item.colour })},on),[_vm._v(_vm._s(item.condition))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description ? item.description : 'No description'))])])],1),_c('td',{staticClass:"movement-type",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({ color: item.statusColour})},on),[_vm._v(_vm._s(item.status))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.hours_spent ? ((item.hours_spent) + " hours") : 'No information'))])])],1),_c('td',{staticClass:"movement-date",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"href":"#"},on:{"click":function () { return _vm.openEquipmentInfo(item.equipment_item_id); }}},on),[_vm._v(" "+_vm._s(item.created_at_short)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.created_at_full))])])],1)])]}}])})],1)],1),_c('EquipmentItem',{ref:"equipmentItem"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }