<template>
  <v-container fluid class="px-4 pt-0">
    <v-row class="py-3">
      <v-col cols="4" class="pb-0">
        <v-tabs v-model="selectedTab" @change="changeTab">
          <v-tab> current statuses </v-tab>
          <v-tab> movements </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="6" class="pb-0">
        <v-row >
          <v-col class="pa-0 d-flex align-end">
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Search by name or code"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <template v-if="selectedTab === 0">
            <v-col class="pa-0 d-flex justify-end">
              <v-menu
                v-model="fromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="date-filter"
                    v-model="tableFilters.from"
                    label="From"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="tableFilters.from"
                  @input="fromMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pa-0 d-flex justify-start">
              <v-menu
                v-model="toMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="date-filter ml-6"
                    v-model="tableFilters.to"
                    label="To"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="tableFilters.to"
                  @input="toMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </template>
          <v-spacer v-else></v-spacer>
        </v-row>
        <v-row v-if="selectedTab === 0">
          <v-col class="d-flex justify-end">
            <v-select 
              label="Active Jobs"
              v-model="activeJobsSelect"
              :items="activeJobsList"
              item-text="location_simple"
              item-value="id"
              clearable>
            </v-select>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-select 
              label="Active User Possessions"
              v-model="activeUserPossessionsSelect"
              :items="activeUserPossessionsList"
              item-text="name"
              item-value="id"
              clearable>
            </v-select>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-select 
              label="Active Warehouse Shelfs"
              v-model="activeWarehouseShelfsSelect"
              :items="activeWarehouseShelfsList"
              clearable>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="pb-0">
        <v-row fill-height>
          <v-col class="d-flex justify-end align-end">
            <v-btn class="mr-2" color="primary" outlined @click="generatePdf">
              pdf
            </v-btn>
            <v-btn color="primary" outlined @click="generateExcel">
              excel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <CurrentStatuses
      v-if="selectedTab === 0"
      ref="currentStatuses"
    ></CurrentStatuses>
    <Movements v-if="selectedTab === 1" ref="movements"></Movements>
    <Snackbar ref="snackbar"></Snackbar>
    <EquipmentMovementForm
      ref="equipmentMovementForm"
      v-on:movement-success="notifyMovementSuccess"
      v-on:movement-fail="notifyMovementFail"
    ></EquipmentMovementForm>
  </v-container>
</template>

<script>
import CurrentStatuses from '@/components/Equipment/CurrentStatuses';
import Movements from '@/components/Equipment/Movements';
import EquipmentMovementForm from '@/components/forms/EquipmentMovementForm';
import Snackbar from '@/components/Snackbar';
import EquipmentItemsApi from '@/api/EquipmentItemsApi';
import EquipmentConditionsApi from '@/api/EquipmentConditionsApi';
import JobsApi from '@/api/JobsApi';
import equipmentMovementsApi from '../api/EquipmentMovementsApi';

export default {
  components: {
    CurrentStatuses,
    Movements,
    EquipmentMovementForm,
    Snackbar,
  },
  data: () => ({
    selectedTab: 0,
    equipmentList: [],
    jobsList: [],
    conditionsList: [],
    fromMenu: false,
    toMenu: false,
    search: '',
    tableFilters: {
      from: null,
      to: null,
    },
    activeJobsSelect: undefined,
    activeUserPossessionsSelect: undefined,
    activeWarehouseShelfsSelect: undefined,
    activeJobsList: [],
    activeUserPossessionsList: [],
    activeWarehouseShelfsList: [],
  }),
  watch: {
    tableFilters: {
      deep: true,
      handler() {
        this.updateFilters();
      },
    },
    search: {
      handler() {
        this.updateSearch();
      },
    },
    selectedTab: {
      handler() {
        if (this.selectedTab == 0) {
          this.activeWarehouseShelfsSelect = undefined;
          this.activeUserPossessionsSelect = undefined;
          this.activeJobsSelect = undefined;
        }
      },
    },
    activeJobsSelect: {
      handler() {
        if (this.activeJobsSelect) {
          this.activeUserPossessionsSelect = undefined;
          this.activeWarehouseShelfsSelect = undefined;
          this.$refs.currentStatuses.loadJobStatuses(this.activeJobsSelect);
        }
        else if (this.activeJobsSelect == undefined && 
                 this.activeUserPossessionsSelect == undefined && 
                 this.activeWarehouseShelfsSelect == undefined) {
          this.$refs.currentStatuses.fetchStatuses();           
        }
      },
    },
    activeUserPossessionsSelect: {
      handler() {
        if (this.activeUserPossessionsSelect) {
          this.activeJobsSelect = undefined;
          this.activeWarehouseShelfsSelect = undefined;
          this.$refs.currentStatuses.loadUserPossessionStatuses(this.activeUserPossessionsSelect);
        }
        else if (this.activeJobsSelect == undefined && 
                 this.activeUserPossessionsSelect == undefined && 
                 this.activeWarehouseShelfsSelect == undefined) {
          this.$refs.currentStatuses.fetchStatuses();
        }
      },
    },
    activeWarehouseShelfsSelect: {
      handler() {
        if (this.activeWarehouseShelfsSelect) {
          this.activeJobsSelect = undefined;
          this.activeUserPossessionsSelect = undefined;
          this.$refs.currentStatuses.loadWarehouseShelfStatuses(this.activeWarehouseShelfsSelect);
        }
        else if (this.activeJobsSelect == undefined && 
                 this.activeUserPossessionsSelect == undefined && 
                 this.activeWarehouseShelfsSelect == undefined) {
          this.$refs.currentStatuses.fetchStatuses();
        }
      },
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchEquipmentList();
      this.fetchConditions();
      this.fetchJobs();
      this.fetchFilters();

      const selectedTab = this.$store.getters.selectedEquipmentTab;

      if (selectedTab) {
        this.selectedTab = selectedTab;
      }
    },
    changeTab() {
      // Keep current tab in local storage
      this.$store.commit('storeEquipmentTab', this.selectedTab);
    },
    async fetchEquipmentList() {
      this.equipmentList = await EquipmentItemsApi.getList();
      this.equipmentList.map((x) => {
        x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
          x.model
        } / ${x.serial_number ? x.serial_number : '-'}`;
      });
      this.$store.commit('storeEquipmentList', this.equipmentList);
    },
    async fetchConditions() {
      this.conditionsList = await EquipmentConditionsApi.getList();
      this.$store.commit('storeConditionsList', this.conditionsList);
    },
    async fetchJobs() {
      this.jobsList = await JobsApi.getList();
      this.jobsList.map((x) => (x.name = `Job ${x.code}: ${x.address}`));
      this.$store.commit('storeJobsList', this.jobsList);
    },
    async fetchFilters() {
      this.activeJobsList = await equipmentMovementsApi.getActiveJobs();
      this.activeUserPossessionsList = await equipmentMovementsApi.getActiveUserPossessions();
      this.activeWarehouseShelfsList = await equipmentMovementsApi.getActiveWarehouseShelfs();
    },
    generatePdf() {
      if (this.selectedTab === 0) {
        this.$refs.currentStatuses.generatePdf();
      } else if (this.selectedTab === 1) {
        this.$refs.movements.generatePdf();
      }
    },
    generateExcel() {
      if (this.selectedTab === 0) {
        this.$refs.currentStatuses.generateExcel();
      } else if (this.selectedTab === 1) {
        this.$refs.movements.generateExcel();
      }
    },
    updateFilters() {
        if (this.tableFilters.from != null && this.tableFilters.to != null)
          this.$refs.currentStatuses.updateFilters(this.tableFilters);
        else if(this.tableFilters.from == null && this.tableFilters.to == null)
          this.$refs.currentStatuses.fetchStatuses();

    },
    updateSearch() {
      if (this.selectedTab === 0) {
        this.$refs.currentStatuses.updateSearch(this.search);
      } else if (this.selectedTab === 1) {
        this.$refs.movements.updateSearch(this.search);
      }
    },
    notifyMovementSuccess() {
      this.$refs.snackbar.showSuccess('Success!');

      // Refresh the page to see the updated status or added movement
      this.selectedTab === 0
        ? this.$refs.currentStatuses.fetchStatuses()
        : this.$refs.movements.fetchMovements();
    },
    notifyMovementFail() {
      this.$refs.snackbar.showError('Equipment movement could not be saved.');
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filter {
  display: flex;
  flex: 0 1 10rem;
}
</style>
