<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          id="statuses-table"
          :headers="statusesHeaders"
          :items="statuses"
          :footer-props="statusesTableFooterProps"
          :items-per-page="statusesPerPage"
          :search="search"
          :loading="loading"
          calculate-widths
          dense
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="status-code" style="padding: 0 5px">
                <TextHighlight :queries="search">
                  {{ item.code }}
                </TextHighlight>
              </td>
              <td class="status-category" style="padding: 0 5px">
                <TextHighlight :queries="search">
                  {{ item.category }}
                </TextHighlight>
              </td>
              <td class="status-model" style="padding: 0 5px">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.model }}</span>
                  </template>
                  <span>{{ item.manufacturer }}</span>
                </v-tooltip>
              </td>
              <td class="status-serial-number" style="padding: 0 5px">
                {{ item.serial_number ? item.serial_number : '-' }}
              </td>
              <td class="status-user" style="padding: 0 5px">
                <TextHighlight :queries="search">
                  {{ item.user_name }}
                </TextHighlight>
              </td>
              <td class="status-location" style="padding: 0 5px">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <!-- <TextHighlight v-on="on" :queries="search">
                      {{ item.location_simple }}
                    </TextHighlight> -->
                    <span v-on="on" :style="{color: item.locationColor}">{{ item.location_simple }}</span>
                  </template>
                  <span>{{ item.location }}</span>
                </v-tooltip>
              </td>
              <td class="status-condition" style="padding: 0 5px">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :style="{ color: item.colour }">{{
                      item.condition
                    }}</span>
                  </template>
                  <span>{{
                    item.description ? item.description : 'No description'
                  }}</span>
                </v-tooltip>
              </td>
              <td class="status-type" style="padding: 0 5px">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :style="{ color: item.statusColour }">{{ item.status }}</span>
                  </template>
                  <span>{{
                    item.hours_spent
                      ? `${item.hours_spent} hours`
                      : 'No information'
                  }}</span>
                </v-tooltip>
              </td>
              <td class="status-date" style="padding: 0 5px">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <a
                      v-on="on"
                      href="#"
                      @click="() => openEquipmentInfo(item.equipment_item_id)"
                    >
                      {{ item.created_at_short }}
                    </a>
                  </template>
                  <span>{{ item.created_at_full }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <EquipmentItem ref="equipmentItem"></EquipmentItem>
  </v-container>
</template>

<script>
import EquipmentItem from '@/components/Equipment/EquipmentItem';
import TextHighlight from 'vue-text-highlight';
import EquipmentMovementsApi from '../../api/EquipmentMovementsApi';
import ExcelHelper from '../../helpers/ExcelHelper';
import PdfHelper from '../../helpers/PdfHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'CurrentStatuses',
  components: {
    EquipmentItem,
    TextHighlight,
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    statuses: [],
    statusesHeaders: [
      { text: 'Equip Id', value: 'code', padding: '0 5px', width: '7%' },
      { text: 'Category', value: 'category', padding: '0 5px', width: '10%' },
      { text: 'Model', value: 'model', padding: '0 5px', width: '15%' },
      {
        text: 'Serial number',
        value: 'serial_number',
        filterable: false,
        padding: '0 5px',
        width: '10%',
      },
      { text: 'User', value: 'user_name', padding: '0 5px', width: '10%' },
      {
        text: 'Location',
        value: 'location_simple',
        padding: '0 5px',
        width: '10%',
      },
      {
        text: 'Condition (Comments)',
        value: 'condition',
        padding: '0 5px',
        width: '20%',
      },
      {
        text: 'Status (Hours Spent)',
        value: 'status',
        filterable: false,
        padding: '0 5px',
        width: '10%',
      },
      {
        text: 'Last update',
        value: 'created_at_full',
        padding: '0 5px',
        width: '8%',
      },
    ],
    statusesTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    statusesPerPage: 20,
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchStatuses();
    },
    updateSearch(search) {
      this.search = search;
    },
    async loadJobStatuses(jobId) {
      this.loading = true;
      this.statuses = await EquipmentMovementsApi.getCurrentJobStatuses(jobId);
      this.formatStatuses();
      this.loading = false;
    },
    async loadUserPossessionStatuses(userId) {
      this.loading = true;
      this.statuses = await EquipmentMovementsApi.getCurrentUserPossessionStatuses(userId);
      this.formatStatuses();
      this.loading = false;
    },
    async loadWarehouseShelfStatuses(shelf) {
      this.loading = true;
      this.statuses = await EquipmentMovementsApi.getCurrentWarehouseShelfStatuses(shelf);
      this.formatStatuses();
      this.loading = false;
    },
    async updateFilters(filters) {
      this.loading = true;
      this.statuses = await EquipmentMovementsApi.getCurrentStatusesBetweenDateRange(filters);
      this.formatStatuses();
      this.loading = false;
    },
    generateExcel() {
      const excelData = this.statuses.map((el) => {
        return {
          'Unique Item Code': el.code,
          Category: el.category,
          Manufacturer: el.manufacturer,
          Model: el.model,
          'Serial number': el.serial_number,
          Status: el.status,
          'Hours Spent': el.hours_spent,
          'Date': el.created_at_full,
          Comment: el.description
        };
      });
      let excelWorkbook = ExcelHelper.writeJson(excelData, 'Current Status');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Status_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      const pdf = PdfHelper.generateFromTable(
        '#statuses-table > div > table',
        'landscape',
        'Current Equipment Statuses',
        'current-statuses'
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Statuses_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },
    async fetchStatuses() {
      this.loading = true;
      this.statuses = await EquipmentMovementsApi.getCurrentStatuses();
      this.formatStatuses();
      this.loading = false;
    },
    formatStatuses() {
      this.statuses.map((item) => {
        if (item.condition === 'Good (Working)') {
          item.colour = 'green';
        } else if (item.condition === 'Bad (Broken)') {
          item.colour = 'red';
        } else {
          item.colour = 'orange';
        }

        item.locationColor = 'black';
        if (item.warehouse_shelf != null) {
          item.location = `Warehouse, shelf ${item.warehouse_shelf}`;
          item.location_simple = `Warehouse, shelf ${item.warehouse_shelf}`;
        }
        else if (item.job_id == null) 
        {
          item.location_simple = 'User Possession';
          item.location = 'User Possession (' + item.user_name + ')';
          item.locationColor = '#E19300';
        }

        if (item.movement === 'Check - in') {
          item.status = 'Checked - in';
          item.statusColour = '#3DA129';
        } else if (item.movement === 'Check - out') {
          item.status = 'Checked - out';
          item.statusColour = '#BF3434';
        } else if (item.movement === 'Change site') {
          item.status = 'Changed site';
        } else if (item.movement === 'Request Repair') {
          item.status = 'Repair Requested';
          item.statusColour = '#3462BF';
        } else {
          item.status = item.movement;
        }
      });
    },
    openEquipmentInfo(id) {
      this.$refs.equipmentItem.openDialog(id);
    },
  },
};
</script>

<style scoped>
.status-serial-number,
.status-location,
.status-date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-code {
  max-width: '7%';
}

.status-category,
.status-serial-number,
.status-user,
.status-location,
.status-type {
  max-width: '10%';
}

.status-model {
  max-width: '15%';
}

.status-condition {
  max-width: '20%';
}

.status-date {
  max-width: '8%';
}
</style>
