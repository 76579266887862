<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Equipment #{{ itemCode }}</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item cols="12" class="pb-0">
              <v-list-item-content>
                <v-list-item-title>Equipment</v-list-item-title>
                <v-list-item-subtitle>{{ equipment }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="maintenanceDone" cols="12" class="pb-0">
              <v-list-item-content>
                <v-list-item-title>Maintenance</v-list-item-title>
                <v-list-item-subtitle>{{
                  maintenanceDone
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item cols="8" class="pb-0">
              <v-list-item-content>
                <v-list-item-title>Location</v-list-item-title>
                <v-list-item-subtitle>{{ job }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item cols="4" class="pb-0">
              <v-list-item-content>
                <v-list-item-title>Condition</v-list-item-title>
                <v-list-item-subtitle>{{ condition }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item cols="12" class="pb-0">
              <v-list-item-content>
                <v-list-item-title>Last comment</v-list-item-title>
                <v-list-item-subtitle>{{ lastComment }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item cols="12" class="pb-0">
              <v-list-item-content>
                <v-list-item-title>Hours spent</v-list-item-title>
                <v-list-item-subtitle>{{ hoursSpent }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item v-if="photo" cols="12" class="pb-0">
              <v-list-item-content>
                <v-list-item-title>Photo</v-list-item-title>
                <v-img :src="convertedPhoto" class="equipment-photo"></v-img>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EquipmentMovementsApi from '../../api/EquipmentMovementsApi';

export default {
  name: 'EquipmentItem',
  components: {},
  data: () => ({
    // Form
    dialog: false,
    valid: false,
    showHoursSpent: false,
    // Equipment item parameters
    itemCode: null,
    equipment: null,
    job: null,
    condition: null,
    photo: null,
    photoMimeType: null,
    lastComment: null,
    hoursSpent: null,
    maintenanceTypes: [],
    maintenanceDone: null,
  }),
  created() {
    this.initialize();
  },
  computed: {
    convertedPhoto() {
      return `data:${this.photoMimeType};base64, ${this.photo}`;
    },
  },
  watch: {},
  methods: {
    async initialize() {},
    async openDialog(equipmentId, movementId = false) {
      this.dialog = true;
      this.clearForm();

      if (movementId) {
        await this.fetchSingleMovement(equipmentId, movementId);
      } else {
        await this.fetchStatus(equipmentId);
      }

      await this.fetchEquipmentItem(equipmentId);

      // if (type === 'MAINTENANCE DONE') {
      //   this.showHoursSpent = true;
      // }
    },
    async closeDialog() {
      this.dialog = false;
    },
    async clearForm() {
      this.itemCode = null;
      this.equipment = null;
      this.condition = null;
      this.job = null;
      this.lastComment = null;
      this.photo = null;
      this.photoMimeType = null;
      this.maintenanceTypes = [];
      this.maintenanceDone = null;
    },
    async fetchSingleMovement(equipmentId, movementId) {
      const movementData = {
        id: movementId,
      };

      const movement = await EquipmentMovementsApi.getSingleMovement(
        movementData
      );

      await this.fetchCondition(movement.equipment_condition_id);
      await this.fetchJob(movement.job_id);

      this.itemCode = movement.code;

      movement.description
        ? (this.lastComment = movement.description)
        : (this.lastComment = 'No description');

      if (movement.is_inspect) {
        this.maintenanceTypes.push('Inspection');
      }

      if (movement.is_maintenance) {
        this.maintenanceTypes.push('Maintenance');
      }

      if (movement.is_repair) {
        this.maintenanceTypes.push('Repair');
      }

      if (movement.is_service) {
        this.maintenanceTypes.push('Service');
      }

      if (this.maintenanceTypes.length > 0) {
        this.maintenanceDone = this.maintenanceTypes.join(', ');
        this.showMaintenanceTypes = true;
      }

      const equipmentData = {
        equipment_item_id: equipmentId,
      };

      const response = await EquipmentMovementsApi.getPhoto(equipmentData);

      if (response) {
        this.photo = response.photo;
        this.photoMimeType = response.mime_type;
      }
    },
    async fetchStatus(equipmentId) {
      const data = {
        equipment_item_id: equipmentId,
      };

      const status = await EquipmentMovementsApi.getCurrentStatus(data);
      this.job = status.location;
      await this.fetchCondition(status.equipment_condition_id);

      this.itemCode = status.code;

      this.lastComment = status.description
        ? `${status.created_at_short}: ${status.description}`
        : 'No comments';

      if (status.is_inspect) {
        this.maintenanceTypes.push('Inspected');
      }

      if (status.is_maintenance) {
        this.maintenanceTypes.push('Maintenance done');
      }

      if (status.is_repair) {
        this.maintenanceTypes.push('Repaired');
      }

      if (status.is_service) {
        this.maintenanceTypes.push('Serviced');
      }

      if (this.maintenanceTypes.length > 0) {
        this.maintenanceDone = this.maintenanceTypes.join(', ');
        this.showMaintenanceTypes = true;
      }

      const response = await EquipmentMovementsApi.getPhoto(data);

      if (response) {
        this.photo = response.photo;
        this.photoMimeType = response.mime_type;
      }
    },
    async fetchEquipmentItem(equipmentId) {
      const equipmentList = await this.$store.getters.equipmentList;

      for (let item of equipmentList) {
        if (item.id === equipmentId) {
          return (this.equipment = `${item.category} / ${item.manufacturer} / ${item.model} / ${item.serial_number}`);
        }
      }
    },
    async fetchCondition(conditionId) {
      const conditionsList = await this.$store.getters.conditionsList;

      for (let condition of conditionsList) {
        if (condition.id === conditionId) {
          return (this.condition = condition.name);
        }
      }
    },
    async fetchJob(jobId) {
      const jobsList = await this.$store.getters.jobsList;
      this.job = this.status.location;

      for (let job of jobsList) {
        if (job.id === jobId) {
          return (this.job = job.name);
        }
      }
    },
  },
};
</script>

<style scoped>
.equipment-photo {
  border: 1px solid;
  max-height: 20rem;
  max-width: 20rem;
}
</style>
